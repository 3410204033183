import {
  bindOrderReference,
  unbindOrderReference,
  updateOrderReference,
} from "@/plugins/firebase";

import { isTotallyPaidFromPlugin } from "../../plugins/isTotallyPaid";

const state = () => ({
  products: [],
  uuid: null,
  tipOptions: new Set([0, 150, 300, 500]),
  currentTip: {
    amount: 0,
    status: "NOT_PAID",
    uuid: undefined,
  },
  status: null,
  payments: [],
});

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_STATUS(state, status) {
    state.status = status;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_CURRENT_TIP(state, { status, amount, uuid }) {
    state.currentTip.status = status;
    state.currentTip.amount = Number(amount);
    state.currentTip.uuid = uuid;
  },
  UPDATE_TIP_OPTIONS(state, tipOptions) {
    state.tipOptions = tipOptions;
  },
  SET_PAYMENTS(state, payments) {
    state.payments = payments;
  },
};

const getters = {
  totalPrice: (state) => {
    let total = 0;

    if (state.products[0]) {
      state.products.forEach((product) => {
        total += Number(product.unitPrice) * Number(product.quantity);
      });
    }

    total += state?.currentTip?.amount || 0;

    return total;
  },

  paymentStatus: () => {
    return ["NOT_PAID", "IN_PROGRESS", "PAID"];
  },

  extendedProducts: (state, getters) => {
    const extendedProducts = [];
    state.products.forEach((element) => {
      const groupOfSameProducts = [];
      const totalQuantity = element.quantity;
      const paidQuantity = element.paidQuantity;
      const payingUUID = [];
      let payingQuantity = 0;

      // Get paying quantity
      if (element.paymentTemp && element.paymentTemp[0]) {
        element.paymentTemp?.forEach((productPayingTemp) => {
          payingQuantity += productPayingTemp.quantity;
          payingUUID.push(productPayingTemp);
        });
      }

      // Clean single product
      for (let index = 0; index < totalQuantity; index++) {
        let singleProduct = {
          name: element.name,
          sku: element.sku,
          unitPrice: element.unitPrice,
          uuid: undefined,
        };
        groupOfSameProducts.push(singleProduct);
      }

      // Set Status
      // 2
      if (paidQuantity) {
        let notPaidProducts = groupOfSameProducts.filter((element) => {
          return !element.status;
        });

        for (
          let index = 0;
          index < paidQuantity && notPaidProducts[0];
          index++
        ) {
          notPaidProducts[index].status = getters.paymentStatus[2];
          notPaidProducts[index].uuid = undefined;
        }
      }

      // 1
      if (payingQuantity > 0) {
        let notPaidProducts = groupOfSameProducts.filter((element) => {
          return !element.status;
        });

        let uuidIndex = 0;
        payingUUID.forEach((payingUUIDElement) => {
          for (
            let index = 0;
            index < payingUUIDElement.quantity;
            index++, uuidIndex++
          ) {
            const element = notPaidProducts[uuidIndex];
            if (element) {
              element.status = getters.paymentStatus[1];
              element.uuid = payingUUIDElement.uuid;
            }
          }
        });
      }

      // 0
      let notPaidProducts = groupOfSameProducts.filter((element) => {
        return !element.status;
      });

      if (notPaidProducts.length > 0) {
        for (let index = 0; index < notPaidProducts.length; index++) {
          const element = notPaidProducts[index];

          element.status = getters.paymentStatus[0];
          element.uuid = undefined;
        }
      }

      extendedProducts.push(...groupOfSameProducts);
    });

    return extendedProducts;
  },

  sortedExtendedProducts: (state, getters) => {
    return getters.extendedProducts.sort((a, b) => {
      return (
        getters.paymentStatus.indexOf(b.status) -
        getters.paymentStatus.indexOf(a.status)
      );
    });
  },
  paidSortedExtendedProducts: (state, getters) => {
    return getters.sortedExtendedProducts.filter(
      (element) => element.status === getters.paymentStatus[2]
    );
  },

  inProgressSortedExtendedProducts: (state, getters) => {
    return getters.sortedExtendedProducts.filter(
      (element) => element.status === getters.paymentStatus[1]
    );
  },

  currentTip: (state) => {
    return state.currentTip;
  },

  uuid: (state) => {
    return state.uuid;
  },
  notPaidSortedExtendedProducts: (state, getters) => {
    return getters.sortedExtendedProducts.filter(
      (element) => element.status === getters.paymentStatus[0]
    );
  },
  notPaidAndPayInProgressSortedExtendedProducts: (state, getters) => {
    return getters.sortedExtendedProducts.filter(
      (element) =>
        element.status === getters.paymentStatus[0] ||
        element.status === getters.paymentStatus[1]
    );
  },

  status: (state) => {
    return state.status;
  },
  remainingPaymentLiveAmount: (state, getters) => {
    let liveAmount = 0;

    liveAmount = getters.inProgressSortedExtendedProducts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.unitPrice,
      0
    );

    if (getters.currentTip.status === getters.paymentStatus[1]) {
      liveAmount += getters.currentTip.amount;
    }

    return getters.remainingPaymentAmount - liveAmount;
  },
  remainingPaymentAmount: (state, getters) => {
    let paidProducts = getters.extendedProducts.filter((element) => {
      return element.status === getters.paymentStatus[2];
    });

    let sum = paidProducts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.unitPrice,
      0
    );

    if (getters.currentTip.status === getters.paymentStatus[2]) {
      sum += getters.currentTip.amount;
    }

    return getters.totalPrice - sum;
  },
};

const actions = {
  bind({ commit, dispatch }, { commerceId, table }) {
    bindOrderReference(
      {
        commerceId,
        table,
      },
      (order) => {
        commit("SET_PRODUCTS", order.details || []);
        commit("SET_PAYMENTS", order.payments || []);
        commit("SET_CURRENT_TIP", order.tip || {});
        commit("SET_STATUS", order.status);
        commit("SET_UUID", order.uuid || null);
        commit("menu/SET_ORIGIN_NAME", order.originName, {
          root: true,
        });
        dispatch("initCurrentTip", order);
      }
    );
  },
  unbind(context, { commerceId, table }) {
    unbindOrderReference({
      commerceId,
      table,
    });
  },
  initCurrentTip({ dispatch }, order) {
    if (!order.tip) {
      // updateOrderReference({
      //   "/order/tip": {
      //     amount: 0,
      //     status: "NOT_PAID",
      //   },
      // });
    } else if (Number.isInteger(order?.tip?.amount) && order?.tip?.status) {
      // dispatch("addNewCurrentTip", order.tip.amount);
      dispatch("sortTipOptions", order?.tip?.amount);
    } else {
      updateOrderReference({
        "/order/tip": {
          amount: 0,
          status: "NOT_PAID",
        },
      });
    }
  },
  resetMultipaymentUUID(context, uuid) {
    const { products, currentTip } = context.state;
    const productsChanging = [];
    products.forEach((product, index) => {
      const paymentTemp = product.paymentTemp?.find(
        (element) => element.uuid === uuid
      );

      if (paymentTemp) {
        const paymentTempIndex = products[index].paymentTemp?.findIndex(
          (element) => element.uuid === uuid
        );

        productsChanging.push({
          productIndex: index,
          paymentTempIndex,
        });
      }
    });

    const updates = {};
    productsChanging.forEach((productChanging) => {
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/uuid`
      ] = null;
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/quantity`
      ] = 0;
    });

    if (currentTip.uuid === uuid) {
      updates[`/order/tip/status`] = context.getters.paymentStatus[0];
      updates[`/order/tip/uuid`] = null;
    }

    updateOrderReference(updates);
    window.localStorage.removeItem("multipaymentUuid");
  },
  resetOrder() {
    updateOrderReference({
      "/order/tip/uuid": null,
      "/order/tip/status": "NOT_PAID",
      "/order/tip/amount": 0,
      "/order/status": "EMPTY",
      "/order/details": null,
      "/order/androidOrderId": null,
      "/order/payments": null,
    });
  },
  setTipUUID(context, uuid) {
    updateOrderReference({
      "/order/tip/uuid": uuid,
      "/order/tip/status": uuid
        ? context.getters.paymentStatus[1]
        : context.getters.paymentStatus[0],
    });
  },
  setOrderUuid(context, uuid) {
    updateOrderReference({
      "/order/uuid": uuid,
    });
    window.localStorage.setItem("orderUuid", uuid);
  },
  setProductUUID(context, { checkboxBoolean, product, uuid }) {
    window.localStorage.setItem("multipaymentUuid", uuid);

    const products = context.state.products;
    const productIndex = products.findIndex(
      (element) => element.sku === product.sku
    );
    const paymentTempIndex = products[productIndex].paymentTemp?.findIndex(
      (element) => element.uuid === uuid
    );

    const updates = {};

    if (paymentTempIndex !== undefined && paymentTempIndex !== -1) {
      updates[
        `/order/details/${productIndex}/paymentTemp/${paymentTempIndex}/quantity`
      ] = checkboxBoolean
        ? products[productIndex].paymentTemp[paymentTempIndex].quantity + 1
        : products[productIndex].paymentTemp[paymentTempIndex].quantity - 1;

      if (
        !checkboxBoolean &&
        products[productIndex].paymentTemp[paymentTempIndex].quantity <= 1
      ) {
        updates[
          `/order/details/${productIndex}/paymentTemp/${paymentTempIndex}/uuid`
        ] = null;
      }
    } else {
      const newProductIndex =
        products[productIndex].paymentTemp &&
        products[productIndex].paymentTemp[0]
          ? products[productIndex].paymentTemp.length
          : 0;
      updates[
        `/order/details/${productIndex}/paymentTemp/${newProductIndex}/quantity`
      ] = 1;
      updates[
        `/order/details/${productIndex}/paymentTemp/${newProductIndex}/uuid`
      ] = uuid;
    }

    updateOrderReference(updates);
  },
  updateFailurePayment(context, { uuid, table }) {
    const { products, currentTip } = context.state;
    console.log(`updateFailurePayment`, uuid, table);
    const productsChanging = [];

    products.forEach((product, index) => {
      const paymentTemp = product.paymentTemp?.find(
        (element) => element.uuid === uuid
      );

      if (paymentTemp) {
        const paymentTempIndex = products[index].paymentTemp?.findIndex(
          (element) => element.uuid === uuid
        );

        productsChanging.push({
          productIndex: index,
          paymentTempIndex,
        });
      }
    });

    const updates = {};
    productsChanging.forEach((productChanging) => {
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/uuid`
      ] = null;
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/quantity`
      ] = 0;
    });

    if (currentTip.uuid === uuid) {
      updates[`/order/tip/status`] = context.getters.paymentStatus[0];
      updates[`/order/tip/uuid`] = null;
    }

    if (uuid === context.getters.uuid) {
      updates[`/order/uuid`] = null;
    }

    updateOrderReference(updates);
    console.log(`updateFailurePayment`, updates);
    window.localStorage.removeItem("multipaymentUuid");
  },
  updateSuccessfulPayment(context, { uuid }) {
    const { products, currentTip, payments, status } = context.state;
    let paymentsWritable = [];

    const productsChanging = [];
    products.forEach((product, index) => {
      const paymentTemp = product.paymentTemp?.find(
        (element) => element.uuid === uuid
      );

      if (paymentTemp) {
        const paymentTempIndex = products[index].paymentTemp?.findIndex(
          (element) => element.uuid === uuid
        );

        paymentsWritable.push({
          amount: paymentTemp.quantity * parseFloat(product.unitPrice),
          timestamp: Date.now(),
          type: "Product",
        });
        productsChanging.push({
          quantity: paymentTemp.quantity,
          productIndex: index,
          paymentTempIndex,
        });
      }
    });

    const updates = {};
    productsChanging.forEach((productChanging) => {
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/uuid`
      ] = null;
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/quantity`
      ] = 0;
      updates[`/order/details/${productChanging.productIndex}/paidQuantity`] =
        products[productChanging.productIndex].paidQuantity +
        productChanging.quantity;
      updates[`/order/status`] = "PARTIALLY_PAID";
    });

    let paymentToWriteInFirebase = payments;
    if (!paymentToWriteInFirebase[0]) {
      paymentToWriteInFirebase = [];
    }

    let amount = 0;
    paymentsWritable.forEach((x) => {
      amount += x.amount;
    });

    if (paymentsWritable[0]) {
      paymentToWriteInFirebase.push({
        amount: amount,
        timestamp: Date.now(),
        type: "Product",
      });
    }

    if (currentTip.uuid === uuid) {
      paymentToWriteInFirebase.push({
        amount: currentTip.amount,
        timestamp: Date.now(),
        type: "Tip",
      });
      updates[`/order/tip/status`] = context.getters.paymentStatus[2];
      updates[`/order/tip/uuid`] = null;
    }

    if (paymentToWriteInFirebase[0]) {
      updates[`/order/payments`] = paymentToWriteInFirebase;
    }

    console.log(
      "PASO - updateSuccessfulPayment",
      updates,
      isTotallyPaidFromPlugin(
        products,
        currentTip.amount,
        currentTip.status,
        status
      )
    );

    if (
      isTotallyPaidFromPlugin(
        products,
        currentTip.amount,
        currentTip.status,
        status
      )
    ) {
      updates[`/order/status`] = "TOTALLY_PAID";
    }

    updateOrderReference(updates);
    window.localStorage.removeItem("multipaymentUuid");
  },
  updateSuccessfulTotalPayment(context) {
    const { products } = context.state;
    const productsChanging = [];
    products.forEach((product, index) => {
      if (product.paymentTemp) {
        product.paymentTemp.forEach((paymentTemp, paymentTempIndex) => {
          productsChanging.push({
            productIndex: index,
            paymentTempIndex,
          });
        });
      }
    });

    const updates = {};
    productsChanging.forEach((productChanging) => {
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/uuid`
      ] = null;
      updates[
        `/order/details/${productChanging.productIndex}/paymentTemp/${productChanging.paymentTempIndex}/quantity`
      ] = 0;

      if (products[productChanging.productIndex].quantity) {
        updates[`/order/details/${productChanging.productIndex}/paidQuantity`] =
          products[productChanging.productIndex].quantity;
      }
    });

    updates[`/order/status`] = "TOTALLY_PAID";
    updates[`/order/uuid`] = null;

    updates[`/order/tip/status`] = context.getters.paymentStatus[2];
    updates[`/order/tip/uuid`] = null;

    updateOrderReference(updates);
  },
  addNewCurrentTip({ dispatch }, newCurrentTip) {
    updateOrderReference({
      "/order/tip/amount": Number(newCurrentTip),
    });

    dispatch("sortTipOptions", newCurrentTip);
  },
  sortTipOptions({ commit, state }, newCurrentTip) {
    const orderedSet = new Set();
    const entries = [Number(newCurrentTip)];

    for (const member of state.tipOptions) {
      entries.push(member);
    }

    for (const entry of entries.sort((a, b) => a - b)) {
      orderedSet.add(entry);
    }

    commit("UPDATE_TIP_OPTIONS", orderedSet);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
