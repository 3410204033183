import { getUUID } from "@/utils/uuid.js";
import {
  bindDeliveryReference,
  unbindDeliveryReference,
  updateDeliveryReference,
  removeDeliveryReference,
} from "@/plugins/firebase";

const locallyStoredDeliveryUuid = window.localStorage.getItem("deliveryUuid");

const state = () => ({
  minimumAmount: 0,
  minimumAmountValid: true,
  shippingCost: 0,
  uuid: locallyStoredDeliveryUuid ? locallyStoredDeliveryUuid : null,
  products: [],
  tipOptions: new Set([0, 150, 300, 500]),
  currentTip: {
    amount: 0,
    status: "NOT_PAID",
  },
  shippingInformation: {
    name: null,
    address: null,
    phone: null,
    email: null,
  },
  isInitialized: false,
  paymentMethod: null,
  deliveryMethod: null,
  selectedPickUpPoint: null,
  helpContactCenter: null,
  minimumDeliveryOrPickupTimeInMinutes: null,
  maximumDeliveryOrPickupTimeInMinutes: null,
});

const mutations = {
  INITIALIZE(state) {
    state.isInitialized = true;
  },
  SET_MINIMUM_AMOUNT_VALID(state, minimumAmountValid) {
    state.minimumAmountValid = minimumAmountValid;
  },

  SET_DELIVERY_OR_PICKUP_MINIMUM_TIME(
    state,
    minimumDeliveryOrPickupTimeInMinutes
  ) {
    state.minimumDeliveryOrPickupTimeInMinutes =
      minimumDeliveryOrPickupTimeInMinutes;
  },
  SET_DELIVERY_OR_PICKUP_MAXIMUM_TIME(
    state,
    maximumDeliveryOrPickupTimeInMinutes
  ) {
    state.maximumDeliveryOrPickupTimeInMinutes =
      maximumDeliveryOrPickupTimeInMinutes;
  },
  SET_MINIMUM_AMOUNT(state, minimumAmount) {
    state.minimumAmount = minimumAmount;
  },
  SET_HELP_CONTACT_CENTER(state, helpContactCenter) {
    state.helpContactCenter = helpContactCenter;
  },
  SET_SHIPPING_COST(state, shippingCost) {
    state.shippingCost = shippingCost;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_PAYMENT_METHOD(state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },
  SET_DELIVERY_METHOD(state, deliveryMethod) {
    state.deliveryMethod = deliveryMethod;
  },
  SET_PICKUP_POINT(state, pickUpPoint) {
    state.selectedPickUpPoint = pickUpPoint;
  },
  SET_SHIPPING_INFORMATION(state, shippingInformation) {
    state.shippingInformation = shippingInformation;
  },
  SET_CURRENT_TIP(state, { amount }) {
    state.currentTip.status = "NOT_PAID";
    state.currentTip.amount = Number(amount);
  },
  UPDATE_TIP_OPTIONS(state, tipOptions) {
    state.tipOptions = tipOptions;
  },
};

const getters = {
  minimumAmount: (state) => {
    return state.minimumAmount;
  },
  minimumAmountValid: (state) => {
    return state.minimumAmountValid;
  },
  helpContactCenter: (state) => {
    return state.helpContactCenter;
  },
  shippingCost: (state) => {
    return state.shippingCost;
  },
  paymentStatus: () => {
    return ["NOT_PAID", "IN_PROGRESS", "PAID"];
  },
  currentTip: (state) => {
    return state.currentTip;
  },
  totalPrice: (state, getters) => {
    let total = getters.totalPriceWithoutShippingCost;

    total += state.shippingCost;

    return total;
  },
  totalPriceWithoutShippingCost: (state) => {
    let total = 0;

    state.products.forEach((product) => {
      total += Number(product.unitPrice) * Number(product.quantity);

      if (product.options && product.options[0]) {
        total += product.options.reduce(
          (previousValue, currentValue) =>
            previousValue +
            currentValue.quantity * Number(currentValue.unitPrice),
          0
        );
      }
    });
    total += state?.currentTip?.amount || 0;

    return total;
  },
  getPaymentMethod: (state) => {
    return state.paymentMethod;
  },
  getDeliveryMethod: (state) => {
    return state.deliveryMethod;
  },
  extendedProducts: (state, getters) => {
    const extendedProducts = [];

    state.products.forEach((element) => {
      const groupOfSameProducts = [];
      const totalQuantity = element.quantity;

      // Clean single product
      for (let index = 0; index < totalQuantity; index++) {
        let singleProduct = {
          name: element.name,
          sku: element.sku,
          unitPrice: element.unitPrice,
          options: element.options && element.options[0] ? element.options : [],
          uuid: undefined,
        };
        groupOfSameProducts.push(singleProduct);
      }

      // 0
      let notPaidProducts = groupOfSameProducts.filter((element) => {
        return !element.status;
      });

      if (notPaidProducts.length > 0) {
        for (let index = 0; index < notPaidProducts.length; index++) {
          const element = notPaidProducts[index];

          element.status = getters.paymentStatus[0];
          element.uuid = undefined;
        }
      }

      extendedProducts.push(...groupOfSameProducts);
    });

    return extendedProducts;
  },

  sortedExtendedProducts: (state, getters) => {
    return getters.extendedProducts.sort((a, b) => {
      return (
        getters.paymentStatus.indexOf(b.status) -
        getters.paymentStatus.indexOf(a.status)
      );
    });
  },

  uuid: (state) => {
    return state.uuid;
  },
};

const actions = {
  bind({ commit, state, dispatch }, { commerceId }) {
    const deliveryUuid = state.uuid;

    if (deliveryUuid) {
      bindDeliveryReference(
        {
          commerceId,
          deliveryUuid,
        },
        (order) => {
          commit("SET_PRODUCTS", order.details || []);
          commit("SET_PICKUP_POINT", order.pickUpPoint || null);
          commit("SET_CURRENT_TIP", order.tip || {});
          commit(
            "SET_SHIPPING_INFORMATION",
            order.shippingInformation || {
              name: null,
              address: null,
              phone: null,
              email: null,
            }
          );

          dispatch("initCurrentTip", order);
        }
      );
    }
  },

  unbind({ state }, { commerceId }) {
    const deliveryUuid = state.uuid;
    if (deliveryUuid) {
      unbindDeliveryReference({
        commerceId,
        deliveryUuid,
      });
    }
  },

  initialize({ commit }) {
    commit("INITIALIZE");
  },

  initCurrentTip({ dispatch }, order) {
    if (!order.tip) {
      updateDeliveryReference({
        "/tip": {
          amount: 0,
        },
      });
    } else if (Number.isInteger(order?.tip?.amount)) {
      dispatch("addNewCurrentTip", order.tip.amount);
    } else {
      updateDeliveryReference({
        "/tip": {
          amount: 0,
        },
      });
    }
  },

  setPaymentMethod({ commit }, paymentMethod) {
    updateDeliveryReference({
      "/bKioskPaymentWay": paymentMethod,
    });
    commit("SET_PAYMENT_METHOD", paymentMethod);
  },
  setDeliveryMethod({ commit }, deliveryMethod) {
    updateDeliveryReference({
      "/deliveryMethod": deliveryMethod,
    });
    commit("SET_DELIVERY_METHOD", deliveryMethod);
  },
  setMinimumAmount({ commit }, minimumAmount) {
    commit("SET_MINIMUM_AMOUNT", minimumAmount);
  },
  setMinimumAmountValid({ commit }, minimumAmountValid) {
    commit("SET_MINIMUM_AMOUNT_VALID", minimumAmountValid);
  },
  setHelpContactCenter({ commit }, helpContactCenter) {
    commit("SET_HELP_CONTACT_CENTER", helpContactCenter);
  },
  setShippingCost({ commit }, shippingCost) {
    commit("SET_SHIPPING_COST", shippingCost);
  },

  setDeliveryOrPickupMinimumTime(
    { commit },
    minimumDeliveryOrPickupTimeInMinutes
  ) {
    commit(
      "SET_DELIVERY_OR_PICKUP_MINIMUM_TIME",
      minimumDeliveryOrPickupTimeInMinutes
    );
  },

  setDeliveryOrPickupMaximumTime(
    { commit },
    maximumDeliveryOrPickupTimeInMinutes
  ) {
    commit(
      "SET_DELIVERY_OR_PICKUP_MAXIMUM_TIME",
      maximumDeliveryOrPickupTimeInMinutes
    );
  },
  setPickUpPoint({ commit }, pickUpPoint) {
    const updateObj =
      pickUpPoint === undefined
        ? { "/pickUpPoint": null }
        : { "/pickUpPoint": pickUpPoint };

    updateDeliveryReference(updateObj);

    commit("SET_PICKUP_POINT", pickUpPoint);
  },

  async initializeDeliveryOrder({ dispatch }, routeQuery) {
    await dispatch("bind", routeQuery).finally(() => {
      updateDeliveryReference({
        "/status": "OPEN",
        "/tip/amount": 0,
      });
    });
  },

  updateUuid({ state }, uuid) {
    state.uuid = uuid;
    window.localStorage.setItem("deliveryUuid", state.uuid);
  },

  async initializeOrder({ state, dispatch }, routeQuery) {
    const { commerceId } = routeQuery;
    if (!state.uuid && commerceId) {
      state.uuid = getUUID();
      window.localStorage.setItem("deliveryUuid", state.uuid);
      await dispatch("initializeDeliveryOrder", routeQuery);
    }
  },

  async addProduct(
    { state, dispatch },
    { product, routeQuery, unitPrice, quantity, options }
  ) {
    const { commerceId } = routeQuery;

    if (!state.uuid && commerceId) {
      state.uuid = getUUID();
      window.localStorage.setItem("deliveryUuid", state.uuid);
      await dispatch("initializeDeliveryOrder", routeQuery);
    }

    const existingProducts = state.products;

    updateDeliveryReference({
      "/details": [
        ...existingProducts,
        {
          pkId: product.uuid,
          description: product.description,
          name: product.name,
          sku: product.sku,
          options,
          unitPrice,
          quantity,
        },
      ],
    });
  },

  async updateProduct({ state }, { routeParams, quantity, options }) {
    const { deliveryCartProductIdentifier } = routeParams;

    const existingProducts = [...state.products];
    existingProducts[deliveryCartProductIdentifier].quantity = quantity;
    existingProducts[deliveryCartProductIdentifier].options = options;

    updateDeliveryReference({
      "/details": [...existingProducts],
    });
  },

  async updateShippingInformation(_, shippingInformation) {
    updateDeliveryReference({
      "/shippingInformation": shippingInformation,
    });
  },

  async cleanDeliveryOrder({ state, commit }, payload) {
    unbindDeliveryReference(payload);
    removeDeliveryReference();

    state.uuid = null;
    state.isInitialized = false;

    commit("SET_PRODUCTS", []);
    commit("SET_CURRENT_TIP", { amount: 0 });
    commit("UPDATE_TIP_OPTIONS", new Set([0, 30, 40, 50]));
    commit("SET_SHIPPING_INFORMATION", {
      name: null,
      address: null,
      phone: null,
    });

    window.localStorage.removeItem("deliveryUuid");
  },

  async removeProduct({ state }, productIndex) {
    const productsHolder = JSON.parse(JSON.stringify(state.products));

    if (productIndex > -1) {
      productsHolder.splice(productIndex, 1);
    }

    updateDeliveryReference({
      "/details": [...productsHolder],
    });
  },

  bKioskAmount({ state }) {
    updateDeliveryReference({
      "/bKioskAmount": getters.totalPrice(state),
    });
  },
  addNewCurrentTip({ dispatch }, newCurrentTip) {
    updateDeliveryReference({
      "/tip/amount": Number(newCurrentTip),
    });

    dispatch("sortTipOptions", newCurrentTip);
  },

  sortTipOptions({ commit, state }, newCurrentTip) {
    const orderedSet = new Set();
    const entries = [Number(newCurrentTip)];

    for (const member of state.tipOptions) {
      entries.push(member);
    }

    for (const entry of entries.sort((a, b) => a - b)) {
      orderedSet.add(entry);
    }

    commit("UPDATE_TIP_OPTIONS", orderedSet);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
