import WebFontLoader from "webfontloader";

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      "Rubik:100,300,400,500,600,700,900&display=swap",
      "Montserrat:100,300,700,900&display=swap",
      "PT+Sans:100,300,400,700&display=swap",
    ],
  },
});
