const isTotallyPaidFromPlugin = (
  products,
  currentTipAmount,
  currentTipStatus,
  orderStatus
) => {
  console.log(
    `isTotallyPaidFromPlugin`,
    products,
    currentTipAmount,
    currentTipStatus,
    orderStatus
  );
  let accumulator = [];
  products.forEach((product) => {
    let newObject = {
      sku: product.sku,
      isPaid: product.quantity === product.paidQuantity && product.quantity > 0,
    };
    accumulator.push(newObject);
  });

  if (currentTipAmount > 0) {
    let tip = {
      sku: "tip",
      isPaid: currentTipStatus === "PAID",
    };
    accumulator.push(tip);
  }

  console.log("ORDER STATUS ->", orderStatus);
  if (orderStatus === "EMPTY") {
    console.log("Return FALSE");
    return false;
  }

  if (orderStatus === "TOTALLY_PAID") {
    console.log("Return TRUE");
    return true;
  }

  if (!accumulator[0]) {
    console.log("Return FALSE");
    return false;
  }

  console.log(`Return ${!accumulator.find((x) => x.isPaid === false)}`);
  return !accumulator.find((x) => x.isPaid === false);
};

export { isTotallyPaidFromPlugin };
