// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VCol, VRow } from "vuetify/lib";
import ChefHat from "@/components/icons/ChefHat.vue";

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow,
  },
});

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: "#112C3F",
        secondary: "#CCF2FF",
        accent: "#666666",
        info: "#00BEFF",
        warning: "#D49D09",
        success: "#46CE7F",
        error: "#FD104F",
      },
      dark: {},
    },
  },
  icons: {
    values: {
      chefHat: {
        component: ChefHat,
      },
    },
  },
});
