<template>
  <v-progress-circular
    :width="width"
    :size="size"
    :color="color"
    indeterminate
  />
</template>

<script>
export default {
  name: "BaseProgressCircular",

  props: {
    width: {
      type: Number,
      default: 15,
    },
    size: {
      type: Number,
      default: 100,
    },
    color: {
      type: String,
      default: "primary lighten-5",
    },
  },
};
</script>
