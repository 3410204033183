import Vue from "vue";
import Vuex from "vuex";
import order from "@/store/modules/order";
import menu from "@/store/modules/menu";
import snackbar from "@/store/modules/snackbar";
import deliveryCart from "@/store/modules/deliveryCart";
import currentProduct from "@/store/modules/currentProduct";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    order,
    menu,
    snackbar,
    deliveryCart,
    currentProduct,
  },
});
