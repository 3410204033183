<template>
  <v-row align="center" :class="boxClass" class="card-border">
    <slot />
  </v-row>
</template>

<script>
export default {
  props: {
    boxClass: {
      type: String,
      default: "fill-height px-6 py-4 mb-8 min-h-6",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 0.22rem solid rgba(232, 232, 232, 1);
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 0.6rem;
}
.min-h-6 {
  min-height: 6rem;
}
</style>