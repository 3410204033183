// Imports
import Vue from "vue";
import Router from "vue-router";

import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/global/Index.vue"),
      children: [
        {
          path: "",
          component: {
            render(createElement) {
              return createElement("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Welcome",
              component: () => import("@/views/public/welcome/Index.vue"),
              beforeEnter: (to, from, next) => {
                if (!to.query.commerceId) {
                  next();
                } else {
                  store
                    .dispatch("menu/getProfile", {
                      commerceId: to.query.commerceId,
                      menuUUID: to.query.menuUUID,
                    })
                    .finally(() => next());
                }
              },
            },
            {
              path: "menu",
              component: {
                render(createElement) {
                  return createElement("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Menu",
                  component: () => import("@/views/public/menu/Index.vue"),
                },
                {
                  path: "order",
                  name: "MenuOrder",
                  component: () =>
                    import("@/views/public/menu/order/Index.vue"),
                },
                {
                  path: "weborders",
                  name: "MenuWeborders",
                  meta: {
                    canRedirectToProduct: true,
                    originName: "Weborders",
                  },
                  component: () =>
                    import("@/views/public/menu/weborders/Index.vue"),
                },
                {
                  path: "order/details",
                  name: "MenuOrderDetails",
                  meta: {
                    appBar: {
                      returnPath: "/menu/order",
                      title: "Mi Pedido",
                    },
                  },
                  component: () =>
                    import("@/views/public/menu/order/details/Index.vue"),
                },
                {
                  path: "weborders/payment",
                  component: {
                    render(createElement) {
                      return createElement("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "MenuWebordersPayment",
                      meta: {
                        appBar: {
                          returnPath: "/menu/weborders",
                          title: "Mi Pedido",
                        },
                        isDelivery: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/weborders/payment/Index.vue"
                        ),
                    },
                    {
                      path: "tip",
                      name: "MenuWebordersPaymentOtherTip",
                      meta: {
                        appBar: {
                          returnPath: "/menu/weborders/payment",
                          title: "Agregar Propina",
                        },
                        isDelivery: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/weborders/payment/other-tip/Index.vue"
                        ),
                    },
                    {
                      path: "success",
                      name: "MenuWebordersPaymentSuccessTotal",
                      meta: {
                        successPayment: true,
                        isDelivery: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/weborders/payment/thank-you/Index.vue"
                        ),
                    },
                    {
                      path: "modo",
                      component: {
                        render(createElement) {
                          return createElement("router-view");
                        },
                      },
                      children: [
                        {
                          path: "success",
                          name: "MenuWebordersPaymentModoSuccessTotal",
                          meta: {
                            successPayment: true,
                            isDelivery: true,
                            isModoSelected: true,
                          },
                          component: () =>
                            import(
                              "@/views/public/menu/weborders/payment/thank-you/Index.vue"
                            ),
                        },
                      ],
                    },
                    {
                      path: "failure",
                      name: "MenuWebordersDetailsPaymentFailure",
                      meta: {
                        successPayment: false,
                        isDelivery: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/weborders/payment/thank-you/Index.vue"
                        ),
                    },
                  ],
                  beforeEnter: (to, from, next) => {
                    if (store.state.menu?.profile?.isWebOrdersEnabled) {
                      next();
                    } else if (!store.state.menu?.profile?.isPaymentEnabled) {
                      next({ name: "MenuOrderDetails", query: to.query });
                    } else {
                      next();
                    }
                  },
                },
                {
                  path: "order/details/payment",
                  component: {
                    render(createElement) {
                      return createElement("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "MenuOrderDetailsPayment",
                      meta: {
                        appBar: {
                          returnPath: "/menu/order",
                          title: "Mi Pedido",
                        },
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/Index.vue"
                        ),
                    },
                    {
                      path: "tip",
                      name: "MenuOrderDetailsPaymentOtherTip",
                      meta: {
                        appBar: {
                          returnPath: "/menu/order/details/payment",
                          title: "Agregar Propina",
                        },
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/other-tip/Index.vue"
                        ),
                    },
                    {
                      path: "partial",
                      name: "MenuOrderDetailsPaymentPartial",
                      meta: {
                        appBar: {
                          returnPath: "/menu/order/details/payment",
                          title: "Pagar Cuenta Parcial",
                        },
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/partial/Index.vue"
                        ),
                    },
                    {
                      path: "success",
                      name: "MenuOrderDetailsPaymentSuccessTotal",
                      meta: {
                        successPayment: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/thank-you/Index.vue"
                        ),
                    },
                    {
                      path: "success-partial",
                      name: "MenuOrderDetailsPaymentSuccessPartial",
                      meta: {
                        successPayment: true,
                        isPartial: true,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/thank-you-partial/Index.vue"
                        ),
                    },
                    {
                      path: "failure",
                      name: "MenuOrderDetailsPaymentFailure",
                      meta: {
                        successPayment: false,
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/order/details/payment/thank-you/Index.vue"
                        ),
                    },
                  ],
                  beforeEnter: (to, from, next) => {
                    if (!store.state.menu?.profile?.isPaymentEnabled) {
                      next({ name: "MenuOrderDetails", query: to.query });
                    } else {
                      next();
                    }
                  },
                },
                {
                  path: "",
                  component: {
                    render(createElement) {
                      return createElement("router-view");
                    },
                  },
                  children: [
                    {
                      path: "weborders/precart",
                      name: "WebordersPrecart",
                      meta: {
                        appBar: {
                          returnPath: `/menu/weborders`,
                          title: "Mi Pedido",
                        },
                      },
                      component: () =>
                        import(
                          "@/views/public/menu/weborders/precart/Index.vue"
                        ),
                    },
                    {
                      path: "product/:productId/extras/:optionsGroupsIndex/:deliveryCartProductIdentifier?",
                      name: "ProductExtras",
                      meta: () => {
                        const {
                          productId,
                          deliveryCartProductIdentifier,
                          optionsGroupsIndex,
                        } = router.app._route.params;

                        const currentProduct = store.state.menu.products.find(
                          (product) => product.uuid === productId
                        );

                        const currentProductOptionGroupName =
                          currentProduct?.optionsGroups[optionsGroupsIndex]
                            ?.name;

                        return {
                          appBar: {
                            returnPath:
                              router.app._route.params
                                .deliveryCartProductIdentifier !== undefined
                                ? `/menu/product/${productId}/${deliveryCartProductIdentifier}`
                                : `/menu/product/${productId}`,
                            title: currentProductOptionGroupName
                              ? currentProductOptionGroupName
                              : "Cargando...",
                          },
                          originName: "Weborders",
                        };
                      },
                      component: () =>
                        import("@/views/public/product/extras/Index.vue"),
                    },
                    {
                      path: "product/:productId/:deliveryCartProductIdentifier?",
                      name: "Product",
                      meta: () => {
                        return {
                          originName: "Weborders",
                          appBar: {
                            returnPath:
                              router.app._route.params
                                .deliveryCartProductIdentifier !== undefined
                                ? "/menu/weborders/precart"
                                : "/menu/weborders",
                          },
                        };
                      },
                      component: () =>
                        import("@/views/public/product/Index.vue"),
                    },
                  ],
                  beforeEnter: (to, from, next) => {
                    if (
                      !store.state.menu?.profile?.isPaymentEnabled &&
                      !store.state.menu?.profile?.isWebOrdersEnabled
                    ) {
                      next({ name: "MenuOrder", query: to.query });
                    } else {
                      next();
                    }
                  },
                },
              ],
              beforeEnter: (to, from, next) => {
                if (!to.query.commerceId) {
                  next({ name: "Welcome" });
                } else {
                  store
                    .dispatch("menu/getProfile", {
                      commerceId: to.query.commerceId,
                      menuUUID: to.query.menuUUID,
                    })
                    .finally(() => next());
                }
              },
            },
            {
              path: "reviews",
              component: {
                render(createElement) {
                  return createElement("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Reviews",
                  component: () => import("@/views/public/reviews/Index.vue"),
                },
                {
                  path: "ThankYouReview",
                  name: "ThankYouReview",
                  component: () =>
                    import("@/views/public/thank-you-review/Index.vue"),
                },
              ],

              beforeEnter: (to, from, next) => {
                if (!to.query.commerceId) {
                  next({ name: "Welcome" });
                } else {
                  store
                    .dispatch("menu/getProfile", {
                      commerceId: to.query.commerceId,
                      menuUUID: to.query.menuUUID,
                    })
                    .finally(() => next());
                }
              },
            },
            {
              path: "orderStatus",
              component: {
                render(createElement) {
                  return createElement("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "OrderStatus",
                  component: () =>
                    import("@/views/public/order-status/Index.vue"),
                },
              ],
              beforeEnter: (to, from, next) => {
                if (!to.query.commerceId) {
                  next({ name: "Welcome" });
                } else {
                  store
                    .dispatch("menu/getProfile", {
                      commerceId: to.query.commerceId,
                      menuUUID: to.query.menuUUID,
                    })
                    .finally(() => next());
                }
              },
            },
          ],
        },
        {
          path: "not-found",
          name: "FourOhFour",
          meta: {
            title: "Parece que esta pagina no existe",
          },
          component: () => import("@/views/public/404/Index.vue"),
        },
        {
          path: "*",
          redirect: () => {
            return { name: "FourOhFour" };
          },
        },
      ],
    },
  ],
});

export default router;
