const state = () => ({
  quantity: 1,
  options: [],
  shouldResetOptionals: true,
});

const mutations = {
  RESET_QUANTITY(state) {
    state.quantity = 1;
  },
  RESET_OPTIONS(state) {
    state.options = [];
  },
  SET_CURRENT_PRODUCT_QUANTITY(state, newQuantity) {
    state.quantity = newQuantity;
  },
  SET_CURRENT_PRODUCT_OPTIONS(state, options) {
    if (options && options[0]) {
      state.options = options;
    } else {
      state.options = [];
    }
  },
  ADD_OPTION(state, newOption) {
    const option = state.options
      ? state.options.find((option) => option.sku === newOption.sku)
      : null;
    if (option) {
      option.quantity = newOption.quantity;
    } else {
      state.options.push(newOption);
    }
  },
  REMOVE_OPTION(state, newOption) {
    const option = state.options
      ? state.options.find((option) => option.sku === newOption.sku)
      : null;

    const index = state.options.indexOf(option);
    if (newOption.quantity > 0) {
      option.quantity = newOption.quantity;
    } else {
      state.options.splice(index, 1);
    }
  },
  SET_SHOULD_RESET_OPTIONALS(state, boolean) {
    state.shouldResetOptionals = boolean;
  },
};

const actions = {
  cleanCurrentProduct({ commit }) {
    commit("RESET_QUANTITY");
    commit("RESET_OPTIONS");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
