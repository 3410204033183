const state = () => ({
  snackbar: {
    active: false,
    text: "My default timeout is set to 4500.",
    timeout: 4500,
    success: false,
  },
});

const mutations = {
  OPEN_SNACKBAR(state, payload) {
    state.snackbar.active = true;
    state.snackbar.text = payload.msg;
    state.snackbar.success = payload.success;
    state.snackbar.timeout = payload.timeout || 4500;
  },
  CLOSE_SNACKBAR(state) {
    state.snackbar.active = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
